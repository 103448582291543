exports.components = {
  "component---src-content-projects-along-for-the-ride-index-mdx": () => import("./../../../src/content/projects/along-for-the-ride/index.mdx" /* webpackChunkName: "component---src-content-projects-along-for-the-ride-index-mdx" */),
  "component---src-content-projects-buswatch-index-mdx": () => import("./../../../src/content/projects/buswatch/index.mdx" /* webpackChunkName: "component---src-content-projects-buswatch-index-mdx" */),
  "component---src-content-projects-creative-code-index-mdx": () => import("./../../../src/content/projects/creative-code/index.mdx" /* webpackChunkName: "component---src-content-projects-creative-code-index-mdx" */),
  "component---src-content-projects-dotopia-cybernauterne-index-mdx": () => import("./../../../src/content/projects/dotopia-cybernauterne/index.mdx" /* webpackChunkName: "component---src-content-projects-dotopia-cybernauterne-index-mdx" */),
  "component---src-content-projects-drone-space-index-mdx": () => import("./../../../src/content/projects/drone-space/index.mdx" /* webpackChunkName: "component---src-content-projects-drone-space-index-mdx" */),
  "component---src-content-projects-fablab-voe-index-mdx": () => import("./../../../src/content/projects/fablab-voe/index.mdx" /* webpackChunkName: "component---src-content-projects-fablab-voe-index-mdx" */),
  "component---src-content-projects-five-axis-laser-cutter-index-mdx": () => import("./../../../src/content/projects/five-axis-laser-cutter/index.mdx" /* webpackChunkName: "component---src-content-projects-five-axis-laser-cutter-index-mdx" */),
  "component---src-content-projects-grow-e-index-mdx": () => import("./../../../src/content/projects/grow-e/index.mdx" /* webpackChunkName: "component---src-content-projects-grow-e-index-mdx" */),
  "component---src-content-projects-hacking-rejsekortet-index-mdx": () => import("./../../../src/content/projects/hacking-rejsekortet/index.mdx" /* webpackChunkName: "component---src-content-projects-hacking-rejsekortet-index-mdx" */),
  "component---src-content-projects-ibiz-index-mdx": () => import("./../../../src/content/projects/ibiz/index.mdx" /* webpackChunkName: "component---src-content-projects-ibiz-index-mdx" */),
  "component---src-content-projects-its-green-index-mdx": () => import("./../../../src/content/projects/its-green/index.mdx" /* webpackChunkName: "component---src-content-projects-its-green-index-mdx" */),
  "component---src-content-projects-living-inside-a-climate-shell-index-mdx": () => import("./../../../src/content/projects/living-inside-a-climate-shell/index.mdx" /* webpackChunkName: "component---src-content-projects-living-inside-a-climate-shell-index-mdx" */),
  "component---src-content-projects-metal-detector-index-mdx": () => import("./../../../src/content/projects/metal-detector/index.mdx" /* webpackChunkName: "component---src-content-projects-metal-detector-index-mdx" */),
  "component---src-content-projects-niklasbuhl-index-mdx": () => import("./../../../src/content/projects/niklasbuhl/index.mdx" /* webpackChunkName: "component---src-content-projects-niklasbuhl-index-mdx" */),
  "component---src-content-projects-pengetraeet-index-mdx": () => import("./../../../src/content/projects/pengetraeet/index.mdx" /* webpackChunkName: "component---src-content-projects-pengetraeet-index-mdx" */),
  "component---src-content-projects-pizza-trashcan-index-mdx": () => import("./../../../src/content/projects/pizza-trashcan/index.mdx" /* webpackChunkName: "component---src-content-projects-pizza-trashcan-index-mdx" */),
  "component---src-content-projects-rainbow-weather-forecast-index-mdx": () => import("./../../../src/content/projects/rainbow-weather-forecast/index.mdx" /* webpackChunkName: "component---src-content-projects-rainbow-weather-forecast-index-mdx" */),
  "component---src-content-projects-saving-water-at-rf-index-mdx": () => import("./../../../src/content/projects/saving-water-at-rf/index.mdx" /* webpackChunkName: "component---src-content-projects-saving-water-at-rf-index-mdx" */),
  "component---src-content-projects-share-chair-index-mdx": () => import("./../../../src/content/projects/share-chair/index.mdx" /* webpackChunkName: "component---src-content-projects-share-chair-index-mdx" */),
  "component---src-content-projects-spatial-ar-index-mdx": () => import("./../../../src/content/projects/spatial-ar/index.mdx" /* webpackChunkName: "component---src-content-projects-spatial-ar-index-mdx" */),
  "component---src-content-projects-termokraten-index-mdx": () => import("./../../../src/content/projects/termokraten/index.mdx" /* webpackChunkName: "component---src-content-projects-termokraten-index-mdx" */),
  "component---src-content-projects-the-game-index-mdx": () => import("./../../../src/content/projects/the-game/index.mdx" /* webpackChunkName: "component---src-content-projects-the-game-index-mdx" */),
  "component---src-content-projects-virtual-whiteboard-index-mdx": () => import("./../../../src/content/projects/virtual-whiteboard/index.mdx" /* webpackChunkName: "component---src-content-projects-virtual-whiteboard-index-mdx" */),
  "component---src-content-projects-wash-e-index-mdx": () => import("./../../../src/content/projects/wash-e/index.mdx" /* webpackChunkName: "component---src-content-projects-wash-e-index-mdx" */),
  "component---src-layouts-projects-page-layout-js": () => import("./../../../src/layouts/projects-page-layout.js" /* webpackChunkName: "component---src-layouts-projects-page-layout-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-art-index-js": () => import("./../../../src/pages/art/index.js" /* webpackChunkName: "component---src-pages-art-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cv-index-js": () => import("./../../../src/pages/cv/index.js" /* webpackChunkName: "component---src-pages-cv-index-js" */),
  "component---src-pages-dev-art-js": () => import("./../../../src/pages/dev/art.js" /* webpackChunkName: "component---src-pages-dev-art-js" */),
  "component---src-pages-dev-borders-js": () => import("./../../../src/pages/dev/borders.js" /* webpackChunkName: "component---src-pages-dev-borders-js" */),
  "component---src-pages-dev-buttons-js": () => import("./../../../src/pages/dev/buttons.js" /* webpackChunkName: "component---src-pages-dev-buttons-js" */),
  "component---src-pages-dev-colors-js": () => import("./../../../src/pages/dev/colors.js" /* webpackChunkName: "component---src-pages-dev-colors-js" */),
  "component---src-pages-dev-cols-js": () => import("./../../../src/pages/dev/cols.js" /* webpackChunkName: "component---src-pages-dev-cols-js" */),
  "component---src-pages-dev-contact-js": () => import("./../../../src/pages/dev/contact.js" /* webpackChunkName: "component---src-pages-dev-contact-js" */),
  "component---src-pages-dev-container-js": () => import("./../../../src/pages/dev/container.js" /* webpackChunkName: "component---src-pages-dev-container-js" */),
  "component---src-pages-dev-display-js": () => import("./../../../src/pages/dev/display.js" /* webpackChunkName: "component---src-pages-dev-display-js" */),
  "component---src-pages-dev-element-js": () => import("./../../../src/pages/dev/element.js" /* webpackChunkName: "component---src-pages-dev-element-js" */),
  "component---src-pages-dev-expandcollapse-js": () => import("./../../../src/pages/dev/expandcollapse.js" /* webpackChunkName: "component---src-pages-dev-expandcollapse-js" */),
  "component---src-pages-dev-form-js": () => import("./../../../src/pages/dev/form.js" /* webpackChunkName: "component---src-pages-dev-form-js" */),
  "component---src-pages-dev-formik-js": () => import("./../../../src/pages/dev/formik.js" /* webpackChunkName: "component---src-pages-dev-formik-js" */),
  "component---src-pages-dev-fullpagebackground-js": () => import("./../../../src/pages/dev/fullpagebackground.js" /* webpackChunkName: "component---src-pages-dev-fullpagebackground-js" */),
  "component---src-pages-dev-grid-2-js": () => import("./../../../src/pages/dev/grid2.js" /* webpackChunkName: "component---src-pages-dev-grid-2-js" */),
  "component---src-pages-dev-grid-js": () => import("./../../../src/pages/dev/grid.js" /* webpackChunkName: "component---src-pages-dev-grid-js" */),
  "component---src-pages-dev-hoverimage-js": () => import("./../../../src/pages/dev/hoverimage.js" /* webpackChunkName: "component---src-pages-dev-hoverimage-js" */),
  "component---src-pages-dev-infobox-js": () => import("./../../../src/pages/dev/infobox.js" /* webpackChunkName: "component---src-pages-dev-infobox-js" */),
  "component---src-pages-dev-links-js": () => import("./../../../src/pages/dev/links.js" /* webpackChunkName: "component---src-pages-dev-links-js" */),
  "component---src-pages-dev-mouse-js": () => import("./../../../src/pages/dev/mouse.js" /* webpackChunkName: "component---src-pages-dev-mouse-js" */),
  "component---src-pages-dev-mousescrollanimation-js": () => import("./../../../src/pages/dev/mousescrollanimation.js" /* webpackChunkName: "component---src-pages-dev-mousescrollanimation-js" */),
  "component---src-pages-dev-projectcard-js": () => import("./../../../src/pages/dev/projectcard.js" /* webpackChunkName: "component---src-pages-dev-projectcard-js" */),
  "component---src-pages-dev-responsive-js": () => import("./../../../src/pages/dev/responsive.js" /* webpackChunkName: "component---src-pages-dev-responsive-js" */),
  "component---src-pages-dev-shadows-js": () => import("./../../../src/pages/dev/shadows.js" /* webpackChunkName: "component---src-pages-dev-shadows-js" */),
  "component---src-pages-dev-writing-js": () => import("./../../../src/pages/dev/writing.js" /* webpackChunkName: "component---src-pages-dev-writing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-credits-index-js": () => import("./../../../src/pages/legal/credits/index.js" /* webpackChunkName: "component---src-pages-legal-credits-index-js" */),
  "component---src-pages-legal-disclaimer-index-js": () => import("./../../../src/pages/legal/disclaimer/index.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-index-js" */),
  "component---src-pages-legal-enterprise-index-js": () => import("./../../../src/pages/legal/enterprise/index.js" /* webpackChunkName: "component---src-pages-legal-enterprise-index-js" */),
  "component---src-pages-legal-faq-index-js": () => import("./../../../src/pages/legal/faq/index.js" /* webpackChunkName: "component---src-pages-legal-faq-index-js" */),
  "component---src-pages-legal-privacy-policy-index-js": () => import("./../../../src/pages/legal/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-index-js" */),
  "component---src-pages-legal-terms-and-conditions-index-js": () => import("./../../../src/pages/legal/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-workwithme-index-js": () => import("./../../../src/pages/workwithme/index.js" /* webpackChunkName: "component---src-pages-workwithme-index-js" */)
}

